import React, { useContext, useEffect, useRef, useState } from "react";
import axios from "axios";
import MesageError from "../../../features/components/MessageError";
import { ReporteFacturaContext } from "./ReporteFacturaProvider";
import { post } from "../../../services";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import {
  StyleSelect,
  StyleInput,
  StyleLabel,
  ButtonStyleDefault,
} from "../../../features/Constantes";
import { getListV2 } from "../../../services";
import { rellenarCeros, getFechaFinApi } from "../../../services/Utilitario";
import NumberFormat from "react-number-format";
import ModalLoading from "./ModalLoading";
import { toast } from "react-toastify";
import { SectionFilter } from "../../../features/components/SectionFilter";
import { ButtonDefault } from "../../../features/components/ButtonDefault";

const FormFactura = () => {
  const {
    formik,
    documentos,
    cargandoData,
    setPaginacion,
    initialStatePaginacion,
    setStart,
    CheckedProp,
    setIsCompletedFiles,
    setDocumentos,
    TipoBusqueda,
    setTipoBusqueda,
  } = useContext(ReporteFacturaContext);
  const permisos = useSelector((store) => store.permisosEmpresa.permisos);
  const styleInactivo =
    "inline-flex px-4 py-2 rounded-t-lg border-b-2 border-transparent hover:text-gray-600 hover:border-gray-300 dark:hover:text-gray-300 group";
  const styleActivo =
    "inline-flex px-4 py-2 text-blue-600 rounded-t-lg border-b-2 border-blue-600 active dark:text-blue-500 dark:border-blue-500 group";

  const [Estados, setEstados] = useState([]);
  const [Establecimientos, setEstablecimientos] = useState([]);
  const [PuntosEmision, setPuntosEmision] = useState([]);
  const [TipoDocumento, setTipoDocumento] = useState([]);
  const [SubmitReporteSimple, setSubmitReporteSimple] = useState(false);
  const [SubmitReporteDetallado, setSubmitReporteDetallado] = useState(false);
  const empresa = useSelector((store) => store.empresa.empresa);
  const usuario = useSelector((store) => store.app.user);

  const cancelTokenSource = useRef();

  const navigate = useNavigate();
  // NUEVO 
  const handleButtonClick = () => {
    navigate('/emision/reporte/subir');
  };


  const handleDownloadFilesPDF = async () => {
    document.getElementById("ModalLoadingButton").click();
    try {
      cancelTokenSource.current = axios.CancelToken.source();
      CheckedProp.setTypeDownload("PDF");
      let documents = CheckedProp.handleSubmitChecked();
      const res = await post(
        `api/ReporteMasivo/Pdf/${empresa.idEmpresa}`,
        documents,
        cancelTokenSource.current.token
      );
      setIsCompletedFiles(1);
    } catch (error) {
      // console.log(error.message);
    }
  };

  const handleDownloadFilesXML = async () => {
    document.getElementById("ModalLoadingButton").click();
    try {
      cancelTokenSource.current = axios.CancelToken.source();
      CheckedProp.setTypeDownload("XML");
      let documents = CheckedProp.handleSubmitChecked();
      const res = await post(
        `api/ReporteMasivo/Xml`,
        documents,
        cancelTokenSource.current.token
      );
      setIsCompletedFiles(1);
    } catch (error) {
      //console.log(error.message);
    }
  };

  const handlerAbortRequest = () => cancelTokenSource.current.cancel();

  useEffect(() => {
    (async () => {
      //ObtenerEstablecimientosPorEmpresa
      const GetAllEstablecimientos = await getListV2(
        empresa,
        "ObtenerEstablecimientosPorEmpresa"
      );
      setEstablecimientos(GetAllEstablecimientos._embedded);
    })();
    (async () => {
      const GetAllEstados = await getListV2(empresa, "GetEstados");
      setEstados(GetAllEstados._embedded);
    })();
    (async () => {
      const GetAllTipoDoc = await getListV2(empresa, "BuscarTiposDocumento");
      if (GetAllTipoDoc == null) return;
      setTipoDocumento(GetAllTipoDoc);
    })();
  }, []);

  useEffect(() => {
    formik.resetForm();
    setDocumentos([]);
  }, [TipoBusqueda]);

  const handleChangePuntoEmision = (e) => {
    formik.setFieldValue("secuencial", "");
    formik.handleChange(e);
  };

  const handleChangeEstablecimiento = async (e) => {
    // let establecimientoId = e.target.selectedOptions[0].getAttribute(
    //   "data-id-establecimiento"
    // );
    try {
      const index = e.target.selectedOptions[0].getAttribute("data-index");

      const establecimiento = Establecimientos[index];
      const GetAllPuntosEmision = await getListV2(
        establecimiento,
        "GetListPuntosEmision"
      );

      setPuntosEmision(GetAllPuntosEmision._embedded);
    } catch (ex) {
      //console.log(ex);
    } finally {
      formik.setFieldValue("secuencial", "");
      formik.handleChange(e);
    }
  };

  /* const handleDownloadEXCEL = async (e) => {
    let tipoFecha = "";

    if (formik.values.tipoFecha === "1") tipoFecha = "FechaEmision";
    if (formik.values.tipoFecha === "2") tipoFecha = "FechaAutorizacion";
    //if (formik.values.tipoFecha === "3") tipoFecha = "FechaRegistro";

    const data = await getListV2(
      empresa,
      "DescargarExcel",
      `?fechaInicio=${formik.values.fechaInicio.replaceAll(
        "-",
        "/"
      )}&fechaFin=${formik.values.fechaFin.replaceAll(
        "-",
        "/"
      )}&tipoDocumento=${formik.values.TipoDocumento}&ptoEmision=${
        formik.values.puntoEmision
      }&establecimiento=${formik.values.establecimiento}&secuencial=${ 
        formik.values.secuencial
      }&estado=${formik.values.estado}&identificacion=${
        formik.values.receptor || ""
      }&campoFecha=${tipoFecha}&idEmpresa=${empresa.idEmpresa}`
    );

    //Logica para descargar el xlsx
    let a = document.createElement("a");
    a.href =
      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
      data.archivo;
    a.download = Date.now() + ".xlsx";
    a.click();
  };
 */
  const handleChangeSearchReportes = (e) => {
    if (e.target.name === "secuencial") {
      if (e.target.value.length > 18) {
        return e.target.value;
      }
    }

    if (e.target.name === "cliente") {
      if (e.target.value.length > 300) return e.target.value;
    }

    formik.handleChange(e);
  };

  const handleDownloadReporteSimple = async (e) => {
    try {
      setSubmitReporteSimple(true);
      const data = await axios.get(
        `api/DocumentoElectronico/ReporteSimple/${usuario.identificacion}/${empresa.idEmpresa
        }?fechaInicio=${formik.values.fechaInicio.replaceAll(
          "-",
          "/"
        )}&fechaFin=${getFechaFinApi(
          formik.values.fechaFin.replaceAll("-", "/")
        )}&tipoDocumento=${formik.values.TipoDocumento}&ptoEmision=${formik.values.puntoEmision
        }&establecimiento=${formik.values.establecimiento}&secuencial=${formik.values.secuencial
        }&estado=${formik.values.estado}
        &Receptor=${formik.values.cliente}
        &TipoFecha=${formik.values.tipoFecha}&idEmpresa=${empresa.idEmpresa}`
      );

      //Logica para descargar el xlsx
      let a = document.createElement("a");
      a.href =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        data.data;
      a.download = Date.now() + ".xlsx";
      a.click();
      //setSubmitReporteSimple(false);
      toast.success("Descarga Completa");
      setSubmitReporteSimple(false);
    } catch (error) {
      setSubmitReporteSimple(false);
      toast.error("Se ha producido un error, Por favor intentalo nuevamente");
      console.error("Error en handleDownloadReporteDetallado:", error);
    }
  };

  // const handleDownloadReporteDetalladoExcel = async (e) => {
  //   try {
  //     setSubmitReporteDetallado(true);
  //     const data = await axios.get(
  //       `api/DocumentoElectronico/ReporteDetalladoExcel/${usuario.identificacion
  //       }/${empresa.idEmpresa
  //       }?fechaInicio=${formik.values.fechaInicio.replaceAll(
  //         "-",
  //         "/"
  //       )}&fechaFin=${getFechaFinApi(
  //         formik.values.fechaFin.replaceAll("-", "/")
  //       )}&tipoDocumento=${formik.values.TipoDocumento}&ptoEmision=${formik.values.puntoEmision
  //       }&establecimiento=${formik.values.establecimiento}&secuencial=${formik.values.secuencial
  //       }&estado=${formik.values.estado}
  //       &Receptor=${formik.values.cliente}
  //       &TipoFecha=${formik.values.tipoFecha}&idEmpresa=${empresa.idEmpresa}`
  //     );
  //     if (data.status === 200) {
  //       setSubmitReporteDetallado(false);
  //       document.getElementById("ModalLoadingButton").click();
  //     }
  //     //Logica para descargar el xlsx
  //     /*  let a = document.createElement("a");
  //     a.href =
  //       "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
  //       data.data;
  //     a.download = Date.now() + ".xlsx";
  //     a.click();
  //     setSubmitExcel(false);
  //     toast.success("Descarga Completa"); */
  //   } catch (error) {
  //     setSubmitReporteDetallado(false);
  //     toast.error("Se ha producido un error, Por favor intentalo nuevamente");
  //     console.error("Error en handleDownloadReporteDetallado:", error);
  //   }
  // };






  
  const handleDownloadReporteDetalladoExcel = async (e) => {
    try {
      setSubmitReporteDetallado(true);
      const data = await axios.get(
        `api/DocumentoElectronico/ReporteDetalladoExcel/${
          usuario.identificacion
        }/${
          empresa.idEmpresa
        }?fechaInicio=${formik.values.fechaInicio.replaceAll(
          "-",
          "/"
        )}&fechaFin=${getFechaFinApi(
          formik.values.fechaFin.replaceAll("-", "/")
        )}&tipoDocumento=${formik.values.TipoDocumento}&ptoEmision=${
          formik.values.puntoEmision
        }&establecimiento=${formik.values.establecimiento}&secuencial=${
          formik.values.secuencial
        }&estado=${formik.values.estado}
        &Receptor=${formik.values.cliente}
        &TipoFecha=${formik.values.tipoFecha}&idEmpresa=${empresa.idEmpresa}`
      );
      if (data.status === 200) {
        setSubmitReporteDetallado(false);
        document.getElementById("ModalLoadingButton").click();
      }
      //Logica para descargar el xlsx
      /*  let a = document.createElement("a");
      a.href =
        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64," +
        data.data;
      a.download = Date.now() + ".xlsx";
      a.click();
      setSubmitExcel(false);
      toast.success("Descarga Completa"); */
    } catch (error) {
      setSubmitReporteDetallado(false);
      toast.error("Se ha producido un error, Por favor intentalo nuevamente");
      console.error("Error en handleDownloadReporteDetallado:", error);
    }
  };












  return (
    <>
      <ModalLoading abortRequest={handlerAbortRequest} />
      <button
        type="button"
        className="hidden hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
        data-bs-toggle="modal"
        data-bs-target="#ModalLoading"
        id="ModalLoadingButton"
      ></button>
      <div className="bg-white dark:bg-gray-700 border dark:border-gray-600 p-3  rounded-lg">
        <h2 className="my-4 pl-4 text-2xl font-semibold text-gray-700 dark:text-gray-200">
          Reporte de documentos
        </h2>
        <ul className="flex flex-wrap -mb-px text-sm font-medium text-gray-500 dark:text-gray-400  justify-start">
          <li className="mr-2">
            <button
              onClick={() => setTipoBusqueda("SIMPLE")}
              className={
                TipoBusqueda === "SIMPLE" ? styleActivo : styleInactivo
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 mr-1"
              >
                <path
                  fillRule="evenodd"
                  d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                  clipRule="evenodd"
                />
              </svg>
              Búsqueda simple
            </button>
          </li>
          <li className="mr-2">
            <button
              onClick={() => setTipoBusqueda("AVANZADA")}
              className={
                TipoBusqueda === "AVANZADA" ? styleActivo : styleInactivo
              }
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-5 h-5 mr-1"
              >
                <path
                  fillRule="evenodd"
                  d="M3.792 2.938A49.069 49.069 0 0112 2.25c2.797 0 5.54.236 8.209.688a1.857 1.857 0 011.541 1.836v1.044a3 3 0 01-.879 2.121l-6.182 6.182a1.5 1.5 0 00-.439 1.061v2.927a3 3 0 01-1.658 2.684l-1.757.878A.75.75 0 019.75 21v-5.818a1.5 1.5 0 00-.44-1.06L3.13 7.938a3 3 0 01-.879-2.121V4.774c0-.897.64-1.683 1.542-1.836z"
                  clipRule="evenodd"
                />
              </svg>
              Búsqueda avanzada
            </button>
          </li>
        </ul>
        <hr className="border-1 dark:border-gray-800 " />
        {/* INICIO REPORTE DOCUMENTOE EMITIDOS */}
        {TipoBusqueda === "AVANZADA" ? (
          //BUSQUEDA AVANZADA
          <div className=" py-3">
            <form
              onSubmit={(e) => {
                setPaginacion(initialStatePaginacion);
                formik.handleSubmit(e);
                setStart(0);
              }}
            >
              <SectionFilter title={"Busqueda"}>
                <div className="grid md:grid-cols-5 md:gap-2 mx-2 gap-2 ">
                  <div className="relative z-0 lg:mb-0   w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Fecha inicio
                    </label>
                    <input
                      type="date"
                      className={StyleInput}
                      name="fechaInicio"
                      id="fechaInicio"
                      value={formik.values.fechaInicio}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder=" "
                    />
                  </div>
                  <div className="relative z-0 lg:mb-0   w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Fecha fin
                    </label>
                    <input
                      type="date"
                      name="fechaFin"
                      id="fechaFin"
                      value={formik.values.fechaFin}
                      onChange={formik.handleChange}
                      className={StyleInput}
                      placeholder=" "
                    />
                  </div>

                  <div className="relative z-0  w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Contribuyente / Ruc
                    </label>
                    <input
                      type="text"
                      className={StyleInput}
                      placeholder=" "
                      id="cliente"
                      name="cliente"
                      value={formik.values.cliente}
                      onChange={handleChangeSearchReportes}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.cliente && formik.errors.cliente ? (
                      <MesageError message={formik.errors.cliente} />
                    ) : null}
                  </div>
                  <div className="relative z-0 lg:mb-0   w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Tipo doc.
                    </label>
                    <select
                      type="text"
                      name="TipoDocumento"
                      id="TipoDocumento"
                      className={StyleSelect}
                      placeholder=" "
                      defaultValue={formik.values.estado}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option key="00" value="">
                        TODOS
                      </option>
                      {TipoDocumento.map((el) => {
                        return (
                          <option key={el.idTipoDocumento} value={el.codigo}>
                            {el.documento}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="relative z-0 lg:mb-0   w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Tipo
                    </label>
                    <select
                      type="select"
                      className={StyleSelect}
                      placeholder=" "
                      id="tipoFecha"
                      defaultValue={formik.values.tipoFecha}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option key="1" value="1">
                        Fecha emisión
                      </option>
                      <option key="2" value="2">
                        Fecha autorizacion
                      </option>
                      <option key="3" value="3">
                        Fecha registro
                      </option>
                    </select>
                  </div>
                </div>

                <div className="flex-col space-y-2 mx-2 mt-2">
                  <ButtonDefault
                    title={"Buscar"}
                    color={"primary"}
                    onClick={() => {
                      CheckedProp.setIsCheckedAll(false);
                      CheckedProp.setDocumentsChecked([]);
                    }}
                    svg={
                      cargandoData ? (
                        <>
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline mr-3 w-4 h-4 animate-spin text-white"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="#E5E7EB"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentColor"
                            />
                          </svg>
                        </>
                      ) : (
                        <>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="-ml-0.5 mr-2 h-5 w-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                            />
                          </svg>
                        </>
                      )
                    }
                  />
                         {/* NUEVO  */}
                {/* <ButtonDefault
                  type={"button"}
                  title={"Subir Facturas"}
                  color={"descargas"}
                  onClick={handleButtonClick}
                  svg={<>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" />
                    </svg>

                  </>}
                /> */}
                {/* NUEVO  */}
                  {documentos.length >= 1 && (
                    <ButtonDefault
                      onClick={handleDownloadReporteDetalladoExcel}
                      color={"secondary"}
                      title={
                        SubmitReporteDetallado
                          ? "Descargando..."
                          : "Doc. Detallado"
                      }
                      type="button"
                      svg={
                        SubmitReporteDetallado ? (
                          <svg
                            aria-hidden="true"
                            role="status"
                            className="inline mr-3 w-4 h-4 animate-spin text-white"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                              fill="#E5E7EB"
                            />
                            <path
                              d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                              fill="currentColor"
                            />
                          </svg>
                        ) : (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="icon icon-tabler icon-tabler-brand-office w-5 h-5 mr-1"
                            viewBox="0 0 55 55"
                            strokeWidth="3"
                            stroke="currentColor"
                            //stroke="#008F39"
                            fill="none"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z" />
                          </svg>
                        )
                      }
                    />
                  )}

                  {CheckedProp.documentsChecked.length > 1 ? (
                    <>
                      <ButtonDefault
                        onClick={handleDownloadFilesPDF}
                        color={"secondary"}
                        svg={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="-ml-0.5 mr-2 h-5 w-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                            />
                          </svg>
                        }
                        title={"Descargar PDF"}
                      />

                      <ButtonDefault
                        onClick={handleDownloadFilesXML}
                        color={"descargas"}
                        svg={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="-ml-0.5 mr-2 h-5 w-5"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                            />
                          </svg>
                        }
                        title={"Descargar XML"}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </SectionFilter>

              <div className="bg-[#dbdbdb] lg:block md:block hidden   dark:bg-gray-700 rounded-lg p-3">
                {/* GRUPO INPUT LABEL BUSQUEDA AVANZADA */}
                <div className="grid md:grid-cols-5 md:gap-2 gap-2 mt-2">
                  <div className="relative z-0 lg:mb-0   w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Fecha inicio
                    </label>
                    <input
                      type="date"
                      className={StyleInput}
                      name="fechaInicio"
                      id="fechaInicio"
                      value={formik.values.fechaInicio}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder=" "
                    />
                  </div>
                  <div className="relative z-0 lg:mb-0   w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Fecha fin
                    </label>
                    <input
                      type="date"
                      name="fechaFin"
                      id="fechaFin"
                      value={formik.values.fechaFin}
                      onChange={formik.handleChange}
                      className={StyleInput}
                      placeholder=" "
                    />
                  </div>

                  <div className="relative z-0  w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Contribuyente / Ruc
                    </label>
                    <input
                      type="text"
                      className={StyleInput}
                      placeholder=" "
                      id="cliente"
                      name="cliente"
                      value={formik.values.cliente}
                      onChange={handleChangeSearchReportes}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.cliente && formik.errors.cliente ? (
                      <MesageError message={formik.errors.cliente} />
                    ) : null}
                  </div>
                  <div className="relative z-0 lg:mb-0   w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Tipo doc.
                    </label>
                    <select
                      type="text"
                      name="TipoDocumento"
                      id="TipoDocumento"
                      className={StyleSelect}
                      placeholder=" "
                      defaultValue={formik.values.estado}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option key="00" value="">
                        TODOS
                      </option>
                      {TipoDocumento.map((el) => {
                        return (
                          <option key={el.idTipoDocumento} value={el.codigo}>
                            {el.documento}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="relative z-0 lg:mb-0   w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Tipo
                    </label>
                    <select
                      type="select"
                      className={StyleSelect}
                      placeholder=" "
                      id="tipoFecha"
                      defaultValue={formik.values.tipoFecha}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option key="1" value="1">
                        Fecha emisión
                      </option>
                      <option key="2" value="2">
                        Fecha autorizacion
                      </option>
                      <option key="3" value="3">
                        Fecha registro
                      </option>
                    </select>
                  </div>
                </div>
                <div className="grid md:grid-cols-5  md:gap-2">
                  <div className="relative z-0 lg:mb-0  w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Establecimiento
                    </label>
                    <select
                      onChange={handleChangeEstablecimiento}
                      type="text"
                      name="establecimiento"
                      id="establecimiento"
                      className={StyleSelect}
                      placeholder=" "
                      defaultValue={formik.values.establecimiento}
                      onBlur={formik.handleBlur}
                    >
                      <option key="default" value="">
                        TODOS
                      </option>
                      {Establecimientos.map((el, index) => {
                        return (
                          <option
                            key={el.idEstablecimiento}
                            data-id-establecimiento={el.idEstablecimiento}
                            data-index={index}
                            value={el.codigo}
                          >
                            {rellenarCeros(el.codigo)} - {el.descripcion}
                          </option>
                        );
                      })}
                    </select>
                  </div>

                  <div className="relative z-0  lg:mb-0 mb-6  w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Punto emisión
                    </label>
                    {formik.values.establecimiento !== "" ? (
                      <select
                        type="text"
                        className={StyleSelect}
                        placeholder=" "
                        name="puntoEmision"
                        id="puntoEmision"
                        defaultValue={formik.values.puntoEmision}
                        onChange={handleChangePuntoEmision}
                        onBlur={formik.handleBlur}
                      >
                        <option key="default" value="">
                          TODOS
                        </option>
                        {PuntosEmision.map((el) => {
                          return (
                            <option key={el.idPuntoEmision} value={el.codigo}>
                              {rellenarCeros(el.codigo)} - {el.descripcion}
                            </option>
                          );
                        })}
                      </select>
                    ) : (
                      <select
                        className={StyleSelect + " bg-gray-600 text-white"}
                        placeholder=" "
                        disabled
                      >
                        <option key="default" value="">
                          TODOS
                        </option>
                      </select>
                    )}
                  </div>

                  <div className="relative z-0  w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Secuencial desde
                    </label>
                    {formik.values.establecimiento !== "" &&
                      formik.values.puntoEmision !== "" ? (
                      <NumberFormat
                        format="#########"
                        className={StyleInput}
                        placeholder=" "
                        id="secuencialDesde"
                        name="secuencialDesde"
                        value={formik.values.secuencialDesde}
                        onChange={handleChangeSearchReportes}
                        onBlur={formik.handleBlur}
                      />
                    ) : (
                      <input
                        type={"text"}
                        disabled
                        className={StyleInput + " bg-gray-500 text-white"}
                      />
                    )}

                    {formik.touched.secuencialDesde &&
                      formik.errors.secuencialDesde ? (
                      <MesageError message={formik.errors.secuencialDesde} />
                    ) : null}
                  </div>

                  <div className="relative z-0  w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Secuencial hasta
                    </label>
                    {formik.values.establecimiento !== "" &&
                      formik.values.puntoEmision !== "" ? (
                      <NumberFormat
                        format="#########"
                        className={StyleInput}
                        placeholder=" "
                        id="secuencialHasta"
                        name="secuencialHasta"
                        value={formik.values.secuencialHasta}
                        onChange={handleChangeSearchReportes}
                        onBlur={formik.handleBlur}
                      />
                    ) : (
                      <input
                        type={"text"}
                        disabled
                        className={StyleInput + " bg-gray-500 text-white"}
                      />
                    )}

                    {formik.touched.secuencialHasta &&
                      formik.errors.secuencialHasta ? (
                      <MesageError message={formik.errors.secuencialHasta} />
                    ) : null}
                  </div>

                  {/* ESTADO BUSQUEDA AVANZADA */}
                  <div className="relative z-0 lg:mb-0 mb-6 w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Estado
                    </label>
                    <select
                      type="text"
                      name="estado"
                      id="estado"
                      className={StyleSelect}
                      placeholder=" "
                      defaultValue={formik.values.estado}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option key="default" value="">
                        TODOS
                      </option>
                      {Estados.map((el) => {
                        return (
                          <option
                            key={el.idEstadoDocumentoElectronico}
                            value={el.idEstadoDocumentoElectronico}
                          >
                            {el.nombre}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  {/*  FIN ESTADO BUSQUEDA AVANZADA */}
                  <div></div>
                  <div className="relative z-0 w-full group"></div>
                </div>
              </div>
              {/* FIN GRUPO INPUT LABEL BUSQUEDA AVANZADA */}
              <div className="lg:flex md:flex hidden space-x-2 mx-2 mt-2">
                <ButtonDefault
                  type={"submit"}
                  title={"Buscar"}
                  color={"primary"}
                  onClick={() => {
                    CheckedProp.setIsCheckedAll(false);
                    CheckedProp.setDocumentsChecked([]);
                  }}
                  svg={
                    cargandoData ? (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-3 w-4 h-4 animate-spin text-white"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="-ml-0.5 mr-2 h-5 w-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </>
                    )
                  }
                />
                       {/* NUEVO  */}
                       {/* <ButtonDefault
                  type={"button"}
                  title={"Subir Facturas"}
                  color={"descargas"}
                  onClick={handleButtonClick}
                  svg={<>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" />
                    </svg>

                  </>}
                /> */}
                {/* NUEVO  */}
                {documentos.length >= 1 && (
                  <ButtonDefault
                    onClick={handleDownloadReporteDetalladoExcel}
                    color={"descargas"}
                    title={
                      SubmitReporteDetallado
                        ? "Descargando..."
                        : "Doc. Detallado"
                    }
                    type="button"
                    svg={
                      SubmitReporteDetallado ? (
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-3 w-4 h-4 animate-spin text-white"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-brand-office w-5 h-5 mr-1"
                          viewBox="0 0 55 55"
                          strokeWidth="3"
                          stroke="currentColor"
                          //stroke="#008F39"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z" />
                        </svg>
                      )
                    }
                  />
                )}

                {CheckedProp.documentsChecked.length > 1 ? (
                  <>
                    <ButtonDefault
                      onClick={handleDownloadFilesPDF}
                      color={"descargas"}
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="-ml-0.5 mr-2 h-5 w-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                          />
                        </svg>
                      }
                      title={"Descargar PDF"}
                    />

                    <ButtonDefault
                      onClick={handleDownloadFilesXML}
                      color={"descargas"}
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="-ml-0.5 mr-2 h-5 w-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                          />
                        </svg>
                      }
                      title={"Descargar XML"}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>




















            </form>
          </div>
        ) : (
          //FIN BUSQUEDA AVANZADA
          //BUSQUEDA SIMPLE
          <div className="px-1 py-3">
            <form
              onSubmit={(e) => {
                setPaginacion(initialStatePaginacion);
                formik.handleSubmit(e);
                setStart(0);
              }}
            >
              <SectionFilter title={"Busqueda"}>
                <div className="lg:grid md:grid md:grid-cols-4  mx-2  mb-2 gap-2 md:gap-2  bg-[#dbdbdb] dark:bg-gray-700  rounded-lg ">
                  <div className="relative z-0 lg:mb-0   w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Fecha inicio
                    </label>
                    <input
                      type="date"
                      className={StyleInput}
                      name="fechaInicio"
                      id="fechaInicio"
                      value={formik.values.fechaInicio}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder=" "
                    />
                  </div>
                  <div className="relative z-0 lg:mb-0   w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Fecha fin
                    </label>
                    <input
                      type="date"
                      name="fechaFin"
                      id="fechaFin"
                      value={formik.values.fechaFin}
                      onChange={formik.handleChange}
                      className={StyleInput}
                      placeholder=" "
                    />
                  </div>
                  <div className="relative z-0  w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Contribuyente / Ruc
                    </label>
                    <input
                      type="text"
                      className={StyleInput}
                      placeholder=" "
                      id="cliente"
                      name="cliente"
                      value={formik.values.cliente}
                      onChange={handleChangeSearchReportes}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.cliente && formik.errors.cliente ? (
                      <MesageError message={formik.errors.cliente} />
                    ) : null}
                  </div>
                  {/* ESTADO BUSQUEDA SIMPLE */}
                  <div className="relative z-0 lg:mb-0  w-full group">
                    <label
                      htmlFor="fechaInicio"
                      className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                    >
                      Estado
                    </label>
                    <select
                      type="text"
                      name="estado"
                      id="estado"
                      className={StyleSelect}
                      placeholder=" "
                      defaultValue={formik.values.estado}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option key="default" value="">
                        TODOS
                      </option>
                      {Estados.map((el) => {
                        return (
                          <option
                            key={el.idEstadoDocumentoElectronico}
                            value={el.idEstadoDocumentoElectronico}
                          >
                            {el.nombre}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="flex-col space-y-2 mt-2">
                    <ButtonDefault
                      type={"submit"}
                      title={"Buscar"}
                      color={"primary"}
                      onClick={() => {
                        CheckedProp.setIsCheckedAll(false);
                        CheckedProp.setDocumentsChecked([]);
                      }}
                      svg={
                        cargandoData ? (
                          <>
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline mr-3 w-4 h-4 animate-spin text-white"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              />
                            </svg>
                          </>
                        ) : (
                          <>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="-ml-0.5 mr-2 h-5 w-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                              />
                            </svg>
                          </>
                        )
                      }
                    />
       {/* NUEVO  */}
       {/* <ButtonDefault
                  type={"button"}
                  title={"Subir Facturas"}
                  color={"descargas"}
                  onClick={handleButtonClick}
                  svg={<>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" />
                    </svg>

                  </>}
                /> */}
                {/* NUEVO  */}
                    {documentos.length >= 1 && (
                      <ButtonDefault
                        onClick={handleDownloadReporteDetalladoExcel}
                        color={"descargas"}
                        title={
                          SubmitReporteDetallado
                            ? "Descargando..."
                            : "Doc. Detallado"
                        }
                        type="button"
                        svg={
                          SubmitReporteDetallado ? (
                            <svg
                              aria-hidden="true"
                              role="status"
                              className="inline mr-3 w-4 h-4 animate-spin text-white"
                              viewBox="0 0 100 101"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="#E5E7EB"
                              />
                              <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentColor"
                              />
                            </svg>
                          ) : (
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="icon icon-tabler icon-tabler-brand-office w-5 h-5 mr-1"
                              viewBox="0 0 55 55"
                              strokeWidth="3"
                              stroke="currentColor"
                              //stroke="#008F39"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            >
                              <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z" />
                            </svg>
                          )
                        }
                      />
                    )}

                    {CheckedProp.documentsChecked.length > 1 ? (
                      <>
                        <ButtonDefault
                          onClick={handleDownloadFilesPDF}
                          color={"descargas"}
                          svg={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="-ml-0.5 mr-2 h-5 w-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                              />
                            </svg>
                          }
                          title={"Descargar PDF"}
                        />

                        <ButtonDefault
                          onClick={handleDownloadFilesXML}
                          color={"descargas"}
                          svg={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="currentColor"
                              className="-ml-0.5 mr-2 h-5 w-5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                              />
                            </svg>
                          }
                          title={"Descargar XML"}
                        />
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </SectionFilter>
              <div className="lg:grid md:grid md:grid-cols-4  sm:hidden mb-2 gap-2 md:gap-2 mt-2 bg-[#dbdbdb] dark:bg-gray-700  rounded-lg p-3">
                <div className="relative z-0 lg:mb-0   w-full group">
                  <label
                    htmlFor="fechaInicio"
                    className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                  >
                    Fecha inicio
                  </label>
                  <input
                    type="date"
                    className={StyleInput}
                    name="fechaInicio"
                    id="fechaInicio"
                    value={formik.values.fechaInicio}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    placeholder=" "
                  />
                </div>
                <div className="relative z-0 lg:mb-0   w-full group">
                  <label
                    htmlFor="fechaInicio"
                    className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                  >
                    Fecha fin
                  </label>
                  <input
                    type="date"
                    name="fechaFin"
                    id="fechaFin"
                    value={formik.values.fechaFin}
                    onChange={formik.handleChange}
                    className={StyleInput}
                    placeholder=" "
                  />
                </div>
                <div className="relative z-0  w-full group">
                  <label
                    htmlFor="fechaInicio"
                    className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                  >
                    Contribuyente / Ruc
                  </label>
                  <input
                    type="text"
                    className={StyleInput}
                    placeholder=" "
                    id="cliente"
                    name="cliente"
                    value={formik.values.cliente}
                    onChange={handleChangeSearchReportes}
                    onBlur={formik.handleBlur}
                  />

                  {formik.touched.cliente && formik.errors.cliente ? (
                    <MesageError message={formik.errors.cliente} />
                  ) : null}
                </div>
                {/* ESTADO BUSQUEDA SIMPE */}
                <div className="relative z-0 lg:mb-0  w-full group">
                  <label
                    htmlFor="fechaInicio"
                    className="peer-focus:font-semibold  text-md text-black dark:text-gray-400 duration-300 text-xs peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8 font-semibold"
                  >
                    Estado
                  </label>
                  <select
                    type="text"
                    name="estado"
                    id="estado"
                    className={StyleSelect}
                    placeholder=" "
                    defaultValue={formik.values.estado}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option key="default" value="">
                      TODOS
                    </option>
                    {Estados.map((el) => {
                      return (
                        <option
                          key={el.idEstadoDocumentoElectronico}
                          value={el.idEstadoDocumentoElectronico}
                        >
                          {el.nombre}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {/* FIN ESTADO BUSQUEDA SIMPLE */}
              </div>
              {/* BOTONES DE BUSQUEDA SIMPLE */}
              <div className="lg:flex md:flex hidden space-x-3">
                <ButtonDefault
                  type={"submit"}
                  title={"Buscar"}
                  color={"primary"}
                  onClick={() => {
                    CheckedProp.setIsCheckedAll(false);
                    CheckedProp.setDocumentsChecked([]);
                  }}
                  svg={
                    cargandoData ? (
                      <>
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-3 w-4 h-4 animate-spin text-white"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      </>
                    ) : (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="-ml-0.5 mr-2 h-5 w-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                          />
                        </svg>
                      </>
                    )
                  }
                />
                {/* NUEVO  */}
                {/* <ButtonDefault
                  type={"button"}
                  title={"Subir Facturas"}
                  color={"descargas"}
                  onClick={handleButtonClick}
                  svg={<>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-5 h-5 text-white">
                      <path strokeLinecap="round" strokeLinejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15m0-3-3-3m0 0-3 3m3-3V15" />
                    </svg>

                  </>}
                /> */}
                {/* NUEVO  */}
                {documentos.length >= 1 && (
                  <ButtonDefault
                    onClick={handleDownloadReporteDetalladoExcel}
                    color={"descargas"}
                    title={
                      SubmitReporteDetallado ? "Descargando..." : "Doc. Detallado"
                    }
                    type="button"
                    svg={
                      SubmitReporteDetallado ? (
                        <svg
                          aria-hidden="true"
                          role="status"
                          className="inline mr-3 w-4 h-4 animate-spin text-white"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="#E5E7EB"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentColor"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-brand-office w-5 h-5 mr-1"
                          viewBox="0 0 55 55"
                          strokeWidth="3"
                          stroke="currentColor"
                          //stroke="#008F39"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M 28.875 0 C 28.855469 0.0078125 28.832031 0.0195313 28.8125 0.03125 L 0.8125 5.34375 C 0.335938 5.433594 -0.0078125 5.855469 0 6.34375 L 0 43.65625 C -0.0078125 44.144531 0.335938 44.566406 0.8125 44.65625 L 28.8125 49.96875 C 29.101563 50.023438 29.402344 49.949219 29.632813 49.761719 C 29.859375 49.574219 29.996094 49.296875 30 49 L 30 44 L 47 44 C 48.09375 44 49 43.09375 49 42 L 49 8 C 49 6.90625 48.09375 6 47 6 L 30 6 L 30 1 C 30.003906 0.710938 29.878906 0.4375 29.664063 0.246094 C 29.449219 0.0546875 29.160156 -0.0351563 28.875 0 Z M 28 2.1875 L 28 6.53125 C 27.867188 6.808594 27.867188 7.128906 28 7.40625 L 28 42.8125 C 27.972656 42.945313 27.972656 43.085938 28 43.21875 L 28 47.8125 L 2 42.84375 L 2 7.15625 Z M 30 8 L 47 8 L 47 42 L 30 42 L 30 37 L 34 37 L 34 35 L 30 35 L 30 29 L 34 29 L 34 27 L 30 27 L 30 22 L 34 22 L 34 20 L 30 20 L 30 15 L 34 15 L 34 13 L 30 13 Z M 36 13 L 36 15 L 44 15 L 44 13 Z M 6.6875 15.6875 L 12.15625 25.03125 L 6.1875 34.375 L 11.1875 34.375 L 14.4375 28.34375 C 14.664063 27.761719 14.8125 27.316406 14.875 27.03125 L 14.90625 27.03125 C 15.035156 27.640625 15.160156 28.054688 15.28125 28.28125 L 18.53125 34.375 L 23.5 34.375 L 17.75 24.9375 L 23.34375 15.6875 L 18.65625 15.6875 L 15.6875 21.21875 C 15.402344 21.941406 15.199219 22.511719 15.09375 22.875 L 15.0625 22.875 C 14.898438 22.265625 14.710938 21.722656 14.5 21.28125 L 11.8125 15.6875 Z M 36 20 L 36 22 L 44 22 L 44 20 Z M 36 27 L 36 29 L 44 29 L 44 27 Z M 36 35 L 36 37 L 44 37 L 44 35 Z" />
                        </svg>
                      )
                    }
                  />
                )}

                {CheckedProp.documentsChecked.length > 1 ? (
                  <>
                    <ButtonDefault
                      onClick={handleDownloadFilesPDF}
                      color={"descargas"}
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="-ml-0.5 mr-2 h-5 w-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                          />
                        </svg>
                      }
                      title={"Descargar PDF"}
                    />

                    <ButtonDefault
                      onClick={handleDownloadFilesXML}
                      color={"descargas"}
                      svg={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="currentColor"
                          className="-ml-0.5 mr-2 h-5 w-5"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
                          />
                        </svg>
                      }
                      title={"Descargar XML"}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
            </form>
          </div>
          //FIN BUSQUEDA SIMPLE
        )}
      </div>
    </>
  );
};

export default FormFactura;