import React, { useContext } from "react";
import { EmitirGuiaRemisionContext } from "./EmitirGuiaRemisionProvider";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import useVistaPrevia from "../hooks/useVistaPrevia";

const SubmitButton = () => {
  const {
    formik,
    DestinatariosList,
    DetalleList,
    InformacionAdicionalList,
    handleResetDocument,
  } = useContext(EmitirGuiaRemisionContext);
  const { GetVistaPreviaGuiaRemision, IsLoading } = useVistaPrevia();

  const empresa = useSelector((store) => store.empresa.empresa);

  const handleVistaPrevia = async () => {
    try {
      let parsedInfo = {
        IdEmpresa: empresa.idEmpresa,
        RucEmpresa: empresa.ruc,
        Establecimiento: formik.values.establecimiento,
        PuntoEmision: formik.values.puntoEmision,
        Secuencial: formik.values.secuencial,
        Ambiente: process.env.REACT_APP_ENVIRONMENT,
        NumAutDocSustento: formik.values.numAutDocSustento,
        FechaEmision: formik.values.fechaInicio,
        FechaInicioTransporte: formik.values.fechaInicio,
        FechaFinTransporte: formik.values.fechaFin,
        TipoIdentificacionTransportista:
          formik.values.tipoIdentificacionTransportista,
        RucTransportista: formik.values.transportistaIdentificacion,
        RazonSocialTransportista: formik.values.transportistaRazonSocial,
        CorreoTransportista: formik.values.transportistaEmail
        .replace(/,/g, ";")
        .replace(/\s+/g, ""),
        IdTransportista: formik.values.IdTransportista,
        Placa: formik.values.placa,
        DirPartida: formik.values.puntoPartidaComprobante,
        destinatario: [...DestinatariosList],
        infoAdicional: InformacionAdicionalList.map((x) => {
          return {
            Nombre: x.nombre,
            Valor: x.valor,
          };
        }),
      };

      if (DestinatariosList.length === 0) {
        toast.error("Falta Destinatario");
        return;
      }

      // if (DestinatariosList[0].detalles.length === 0) {
      //   toast.info("Debe agregar un detalle");
      //   return;
      // }

      if (formik.values.transportistaEmail !== null)
        if (formik.values.transportistaEmail.trim() !== "")
          parsedInfo.infoAdicional.push({
            Valor: formik.values.transportistaEmail
            .replace(/,/g, ";")
            .replace(/\s+/g, ""),
            Nombre: "email",
          });

      let data = await GetVistaPreviaGuiaRemision(parsedInfo);

      if (data.includes("System.NullReferenceException")) {
        toast.error("Hubo un error al obtener la vista previa.");
        return;
      }

      if (data === "") {
        toast.error("Faltan datos por completar");
        return;
      }

      let byteCharacters = atob(data);
      let byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      let byteArray = new Uint8Array(byteNumbers);
      let file = new Blob([byteArray], { type: "application/pdf;base64" });
      let fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (erro) {
      //console.log(erro);
    }
  };

  return (
    <>
      {IsLoading && (
        <div
          style={{ width: "100vw", height: "100vh", zIndex: "100" }}
          className="bg-opacity-70 fixed justify-center align-middle top-0 l-0 bg-white text-center"
        >
          <div className="w-full h-full flex justify-center items-center py-4">
            <div
              className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full text-blue-600"
              role="status"
            >
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
      )}
      <div className="flex  space-x-2">
        <div className="group relative border-gray-300 border rounded-md   inline-block">
          <button
            type="button"
            onClick={handleVistaPrevia}
            className="inline-flex h-10 w-10 cursor-pointer items-center justify-center rounded-xl  hover:shadow-md    transition dark:bg-gray-900 dark:hover:bg-gray-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="h-6 w-6 dark:text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>

            <div className="absolute mx-auto w-24  -top-8 -left-8 hidden dark:bg-white dark:text-black    rounded-md bg-gray-800 group-hover:flex text-center p-1">
              <p className="text-white dark:text-black  mx-auto text-sm">
                Vista previa{" "}
              </p>
            </div>
          </button>
        </div>
        <button
          onClick={formik.handleSubmit}
          type="submit"
          className="border p-2 rounded-lg bg-[#04BC53] hover:bg-[#03a448] transition-all duration-150 dark:bg-gray-900 dark:border-gray-600 w-auto flex"
          data-bs-toggle="tooltip"
          title="GUARDAR DOCUMENTO"
        >
          <p className=" mx-2 text-white">Enviar al SRI</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6 mr-2 text-white"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
            />
          </svg>
        </button>
      </div>
    </>
  );
};

export default SubmitButton;
