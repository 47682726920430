import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import DashboarItem from "./DashboarItem";
import ModalInfo from "./ModalInfo";
import factura from "../../img/contribuyente.png";
import ModalComunicado from "./ModalComunicado";
import { Splide, SplideTrack, SplideSlide } from "@splidejs/react-splide";
import CardDasboard from "../../features/components/CardDasboard";
import { useAuth } from "react-oidc-context";


function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}


const Dashboard = () => {
  const permisos = useSelector((store) => store.permisosEmpresa.permisos);
  const [IsDownSRI, setIsDownSRI] = useState(process.env.REACT_APP_SRI_IS_DOWN);
  const [Comunicado, setComunicado] = useState(
    process.env.REACT_APP_COMUNICATE_INTUITO
  );

  const auth = useAuth();


  const cerrarSesion = async () => {
    const logoutUrl = `${auth.settings.authority}connect/endsession?id_token_hint=${auth.user?.id_token}`;
    sessionStorage.clear();
    localStorage.clear();
    await new Promise((resolve) => {
      window.location.href = logoutUrl;
      resolve();
    });
  };


  const empresa = useSelector((store) => store.empresa.empresa);
  const splideRef = useRef(null);
  useEffect(() => {
    if (splideRef.current) {
      const splideInstance = splideRef.current.splide;
      const prevArrow = document.querySelector(".custom-prev-arrow");
      const nextArrow = document.querySelector(".custom-next-arrow");
      if (prevArrow && nextArrow) {
        prevArrow.addEventListener("click", () => splideInstance.go("-1"));
        nextArrow.addEventListener("click", () => splideInstance.go("+1"));
      }
    }
  }, []);
  useEffect(() => {
    if (IsDownSRI === "1") {
      document.getElementById("SriIsDownModalButton").click();
      setIsDownSRI("0");
    }
  }, []);

  useEffect(() => {
    if (Comunicado === "1") {
      document.getElementById("ComunicadoButton").click();
      setComunicado("0");
    }
  }, []);

  return (
    <div className="lg:mx-4 md:mx-4 lg:my-4 md:my-4">
      <ModalInfo />
      <ModalComunicado />
      <button
        className=" hidden hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
        data-bs-toggle="modal"
        data-bs-target="#SriIsDownModal"
        id="SriIsDownModalButton"
      ></button>
      <button
        className=" hidden hover:bg-gray-200  dark:hover:bg-gray-700  rounded-lg p-2  text-sm font-medium leading-5  dark:text-gray-400 focus:outline-none focus:shadow-outline-grayduration-150 ease-in-out"
        data-bs-toggle="modal"
        data-bs-target="#Comunicado"
        id="ComunicadoButton"
      ></button>


      <div className=" shadow pt-8 bg-white dark:bg-gray-900  sm:rounded-lg sm:p-6">
        <h3 className="text-2xl font-medium text-center leading-6 text-blue-600 dark:text-white select-none">
          {/* {empresa && empresa.razonSocial ? (

            <span>
              Bienvenido(a) al facturador de{" "}
              <span className="dark:text-white text-[#00314D]" >{empresa.razonSocial}</span>
            </span>
          ) : (
            <i>CARGANDO...</i>

          )} */}


            <span>
              Bienvenido(a) al facturador de{" "}
              <span style={{ color: "#004977" }}>{empresa.razonSocial}</span>
            </span>
      

        </h3>
        <div className=" my-3 bg-white dark:bg-gray-700   p-4 rounded-lg ">
          <div className="my-4 ">
            <div className="border-b border-b-gray-400/50  ">
              <p className="font-semibold text-lg mx-6 dark:text-white">
                ¿Qué deseas hacer ahora?
              </p>
              {permisos.length == 0 && <button
                            onClick={() => cerrarSesion()}
                            data-bs-toggle="modal"
                            data-bs-target="#trazabilidadModal"
                            tabindex="-1"
                            className={classNames(
                             "text-gray-700 dark:text-gray-400 flex justify-end",
                              " px-4 py-2 text-sm w-full text-left group flex items-center "
                            )}
                          >
                            <svg
                              className="mr-1 h-5 w-5 "
                              aria-hidden="true"
                              fill="none"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"></path>
                            </svg>
                            Cerrar Sesión
                          </button>}
             
              <div className="grid grid-cols-1 gap-6 my-auto p-6  sm:grid-cols-1 md:grid-cols-2  lg:grid-cols-4">
                <DashboarItem
                  isActive={
                    permisos.find((el) => el.codigo === "13") ? true : false
                  }
                  title={"Crear una factura electrónica"}
                  route={"/emision/generar/factura"}
                  svg={
                    <svg>
                      <path d="M20 11.08V8l-6-6H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h6" />
                      <path d="M14 3v5h5M18 21v-6M15 18h6" />
                    </svg>
                  }
                />

                <DashboarItem
                  isActive={
                    permisos.find((el) => el.codigo === "03") ? true : false
                  }
                  title={"Consultar de documentos electrónicos"}
                  route={"/emision/reporte/general"}
                  svg={
                    <svg>
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M14 3v4a1 1 0 0 0 1 1h4" />
                      <path d="M12 21h-5a2 2 0 0 1 -2 -2v-14a2 2 0 0 1 2 -2h7l5 5v4.5" />
                      <path d="M16.5 17.5m-2.5 0a2.5 2.5 0 1 0 5 0a2.5 2.5 0 1 0 -5 0" />
                      <path d="M18.5 19.5l2.5 2.5" />
                    </svg>
                  }
                />

                <DashboarItem
                  isActive={
                    permisos.find((el) => el.codigo === "12") ? true : false
                  }
                  title={"Registrar contribuyentes"}
                  route={"/emision/reporte/contribuyentes"}
                  svg={
                    <svg>
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M8 7a4 4 0 1 0 8 0a4 4 0 0 0 -8 0" />
                      <path d="M16 19h6" />
                      <path d="M19 16v6" />
                      <path d="M6 21v-2a4 4 0 0 1 4 -4h4" />
                    </svg>
                  }
                />

                <DashboarItem
                  isActive={
                    permisos.find((el) => el.codigo === "23") ? true : false
                  }
                  title={"Crear Producto/Servicios"}
                  route={"/emision/reporte/servicios"}
                  svg={
                    <svg>
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M12.5 21h-3.926a3 3 0 0 1 -2.965 -2.544l-1.255 -8.152a2 2 0 0 1 1.977 -2.304h11.339a2 2 0 0 1 1.977 2.304l-.263 1.708" />
                      <path d="M16 19h6" />
                      <path d="M19 16v6" />
                      <path d="M9 11v-5a3 3 0 0 1 6 0v5" />
                    </svg>
                  }
                />
              </div>
            </div>
          </div>

          <div className="relative ">

         
            <button className=" ml-0 custom-prev-arrow lg:flex   hidden justify-center absolute top-[45%] z-10 border p-2  rounded-lg   text-sm font-semibold outline-2 outline-offset-2 transition-colors bg-white text-black  active:bg-gray-200 active:text-white/80">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z"
                  clipRule="evenodd"
                />
              </svg>
            </button>


            <p className="font-semibold text-lg mx-6 dark:text-white">
            Mantente al dia con las novedades de Firma y Facturador
              </p>
         
            <Splide
              ref={splideRef}
              className="my-carousel m-0 p-0  bg-transparent lg:mx-4 md:px-4  "
              options={{
                rewind: true,
                autoplay: true,
                loop: true,
                smartSpeed: 1000,
                padding: "0rem",
                margin: "0rem",
                gap: "2rem",
                pagination: true,
                arrows: false,
                perPage: 2,
                breakpoints: {
                  640: {
                    perPage: 1,
                  },
                  768: {
                    perPage: 2,
                  },
                  1024: {
                    perPage: 2,
                  },
                },
              }}
            >
              
              {/* <SplideSlide className="bg-transparent">
                <CardDasboard
                  text={"Solicitar firma electrónica"}
                  link={
                    "https://www.instagram.com/reel/C5yVF-3Jon_/?utm_source=ig_web_button_share_sheet&igsh=MzRlODBiNWFlZA=="
                  }
                  description={
                    "Obtén tu firma electrónica en pocos pasos desde nuestra plataforma digital."
                  }
                />
              </SplideSlide> */}
              <SplideSlide>
                <CardDasboard
                  text={"¿Cómo firmar desde tú móvil?"}
                  link={
                    "https://www.instagram.com/reel/Cvh2tket47h/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                  }
                  description={
                    "Firma documentos digitales de manera fácil y segura desde tu dispositivo móvil."
                  }
                />
              </SplideSlide>
              <SplideSlide>
                <CardDasboard
                  text={"Actualiza tu firma en el facturador"}
                  link={
                    "https://www.instagram.com/reel/C1KrdGKRsf1/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                  }
                  description={
                    "Garantiza la validez y seguridad de tus transacciones digitales manteniendo actualizada tu firma electrónica."
                  }
                />
              </SplideSlide>
              <SplideSlide>
                <CardDasboard
                  text={"¿Cómo realizar facturas electrónicas?"}
                  link={
                    "https://www.instagram.com/reel/Cvds8XitFXm/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                  }
                  description={
                    "Aprende a generar facturas electrónicas con autorización del SRI."
                  }
                />
              </SplideSlide>
              <SplideSlide>
                <CardDasboard
                  text={"Anulación de facturas electrónicas"}
                  link={
                    "https://www.instagram.com/reel/CxTNIPBrxNq/?utm_source=ig_web_copy_link&igsh=MzRlODBiNWFlZA=="
                  }
                  description={
                    "Anula tus facturas previamente autorizadas en la plataforma del SRI, corrigiendo errores contables."
                  }
                />
              </SplideSlide>
            </Splide>
            <button className=" custom-next-arrow lg:flex hidden justify-center absolute top-[45%] right-0  z-10 border p-2  rounded-lg   text-sm font-semibold outline-2 outline-offset-2 transition-colors bg-white text-black  active:bg-gray-200 active:text-white/80">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6 rotate-180"
              >
                <path
                  fillRule="evenodd"
                  d="M20.25 12a.75.75 0 01-.75.75H6.31l5.47 5.47a.75.75 0 11-1.06 1.06l-6.75-6.75a.75.75 0 010-1.06l6.75-6.75a.75.75 0 111.06 1.06l-5.47 5.47H19.5a.75.75 0 01.75.75z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
